<template>
  <v-row
    class="notification-item cursor-pointer mx-0"
    :class="{
      'is-read': notification.is_read,
      'is-unread': !notification.is_read,
      'is-small': small,
      'font-size-14': $vuetify.breakpoint.xsOnly,
    }"
    @click="onClick"
  >
    <!-- Avatar -->
    <v-col cols="auto" class="pr-0 pr-sm-3">
      <v-avatar :size="small || $vuetify.breakpoint.xsOnly ? 40 : 50">
        <v-img
          :src="getImage()"
          alt="Image"
          @error="
            notification.sender.image.image_url =
              $utils.placeholderImage('50x50')
          "
        />
      </v-avatar>
    </v-col>

    <!-- Content -->
    <v-col :class="{ 'px-0': small }">
      <!-- Header -->
      <div class="top d-flex align-end font-weight-medium mb-1">
        <!-- Unread -->
        <div
          v-if="!notification.is_read"
          class="bullet mr-2 mr-sm-3"
          style="color: var(--v-primary-base)"
        >
          &bull;
        </div>

        <!-- Title -->
        <div>
          {{ $t("notifications.titles." + notification.category) }}
        </div>

        <div class="mx-2 mx-sm-3"></div>

        <!-- Date -->
        <Datetime
          v-if="!small"
          :value="notification.created_at"
          class="text--secondary"
          style="font-size: 12px"
          :fromNow="true"
        />
      </div>

      <!-- Date -->
      <Datetime
        v-if="small"
        :value="notification.created_at"
        class="text--secondary"
        style="font-size: 12px"
        :fromNow="true"
      />

      <!-- Text -->
      <div class="text text--secondary">
        {{ getNotificationText() }}
      </div>

      <!-- Actions -->
      <div>
        <FollowRequestAction
          v-if="notification.category === 'UserFollowRequest'"
          :notification="notification"
          :user="notification.notifable"
          @close="$emit('close')"
          class="mt-2"
        />

        <GroupInviteAction
          v-if="notification.category === 'GroupInvite'"
          :notification="notification"
          :group="notification.notifable"
          @close="$emit('close')"
          class="mt-2"
        />
      </div>
    </v-col>

    <!-- Menu -->
    <v-col v-if="!small" cols="auto" align-self="center">
      <NotificationMenu :notification="notification" />
    </v-col>
  </v-row>
</template>

<script>
import FollowRequestAction from "./actions/FollowRequestAction.vue";
import GroupInviteAction from "./actions/GroupInviteAction.vue";
import NotificationMenu from "./NotificationMenu.vue";

export default {
  components: { FollowRequestAction, GroupInviteAction, NotificationMenu },

  props: {
    notification: Object,
    small: Boolean,
  },

  created() {
    if (this.notification.created) {
      this.$set(this.notification, "created_at", this.notification.created);
    }
  },

  methods: {
    getImage() {
      if (this.notification.notifable_type === "group") {
        return this.$utils.userImage(this.notification.notifable);
      }

      return this.$utils.userImage(this.notification.sender);
    },

    getNotificationText() {
      const data = {};
      const localeString =
        "notifications.messages." + this.notification.category;

      if (this.notification.sender) {
        data.user = this.notification.sender.username;
      }

      switch (this.notification.notifable_type) {
        case "group":
          data.group = this.notification.notifable.name;
          break;
      }

      return this.$t(localeString, data);
    },

    markAsRead() {
      this.$store
        .dispatch("notifications/markAsRead", this.notification.id)
        .then(() => {
          this.notification.is_read = true;
        });
    },

    onClick() {
      try {
        this.$router.push(this.getRoute()).catch(() => {});
        this.$emit("close");
      } catch (e) {
        this.$toast.warning(this.$t("notifications.notAvailable"));
      }

      this.notification.is_read || this.markAsRead();
    },

    getRoute() {
      let route;

      switch (this.notification.notifable_type) {
        case "post":
          route = {
            name: "app.post",
            params: {
              id: this.notification.notifable.id,
            },
          };
          break;

        case "post_comment":
          route = {
            name: "app.post",
            params: {
              id: this.notification.notifable.post_id,
            },
          };
          break;

        case "group":
          switch (this.notification.category) {
            case "GroupInvite":
              route = {
                name: "app.groups",
              };
              break;

            case "GroupRequest":
              route = {
                name: "app.group",
                params: {
                  id: this.notification.notifable.id,
                  openMemberRequests: 1,
                },
              };
              break;

            default:
              route = {
                name: "app.group",
                params: {
                  id: this.notification.notifable.id,
                },
              };
              break;
          }
          break;

        case "user":
          switch (this.notification.category) {
            case "UserFollowRequest":
              route = {
                name: "app.user.follows",
                params: {
                  id: this.$user.id,
                },
                query: {
                  tab: 2,
                },
              };
              break;

            default:
              route = {
                name: "app.user",
                params: {
                  id: this.notification.notifable.id,
                },
              };
              break;
          }
          break;
      }

      return route;
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-item {
  &.is-small {
    .top {
      margin-bottom: -5px !important;
    }
  }
}
</style>
