<template>
  <!-- Action buttons -->
  <div>
    <!-- Accept -->
    <v-btn
      color="primary"
      rounded
      small
      class="mr-2"
      :loading="$loading('follows/acceptRequest') === user.id"
      @click.stop="accept"
    >
      <v-icon left>mdi-check</v-icon>
      {{ $t("common.accept") }}
    </v-btn>

    <!-- Decline -->
    <v-btn
      color="secondary"
      rounded
      small
      class="ml-2"
      :loading="$loading('follows/rejectRequest') === user.id"
      @click.stop="decline"
    >
      <v-icon left>mdi-cancel</v-icon>
      {{ $t("common.decline") }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    notification: Object,
    user: Object,
  },

  methods: {
    accept() {
      this.$store
        .dispatch("follows/acceptRequest", this.user.id)
        .then(() => {
          this.$store.commit("notifications/DELETE", this.notification);
          this.$toast.info(
            this.$t("users.followRequestAccepted", {
              username: this.user.username,
            })
          );
        })
        .catch(() => {
          this.$toast.error("Error while accepting follow request.");
        });
    },

    decline() {
      this.$store
        .dispatch("follows/rejectRequest", this.user.id)
        .then(() => {
          this.$store.commit("notifications/DELETE", this.notification);
          this.$toast.warning(
            this.$t("users.followRequestRejected", {
              username: this.user.username,
            })
          );
        })
        .catch(() => {
          this.$toast.error("Error while rejecting follow request.");
        });
    },
  },
};
</script>

<style></style>
