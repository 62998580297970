<template>
  <v-container class="pa-0 h-100" fluid>
    <v-row align="center" class="ma-0 h-100">
      <!-- Logo -->
      <v-col cols="auto" class="d-flex align-center px-0 px-sm-3">
        <router-link :to="{ name: 'app' }">
          <v-img
            :src="require('@/assets/logo/logo-raw.png')"
            :width="$xs ? 60 : 80"
            :max-width="$xs ? 60 : 80"
            alt="Payfoot logo"
            contain
            class="cursor-pointer"
            @click="onLogoClick"
          />
        </router-link>
      </v-col>

      <!-- Search -->
      <v-col>
        <div class="d-flex gap-30">
          <HeaderGlobalSearch />

          <a
            v-if="$vuetify.breakpoint.mdAndUp"
            href="http://metaverse.payfoot.com/"
            target="_blank"
            class="rounded"
          >
            <v-img
              :src="require('@/assets/images/metaverse-banner-header.png')"
            />
          </a>
        </div>
      </v-col>

      <!-- Menu buttons -->
      <v-col cols="auto" class="d-flex align-center justify-end px-0 px-sm-3">
        <template v-if="$vuetify.breakpoint.mdAndUp">
          <!-- Messages -->
          <ChatModal v-model="chatModal">
            <HeaderMenuItem
              icon="mdi-chat-processing"
              :text="$t('common.messages')"
              :dot="$store.getters['chat/hasUnread']"
              :active="$route.name === 'app.chat'"
              @click="chatModal = true"
            />
          </ChatModal>

          <!-- Notifications -->
          <NotificationsModal
            v-if="$vuetify.breakpoint.smAndUp"
            v-model="notificationModal"
          >
            <HeaderMenuItem
              icon="mdi-bell"
              :text="$t('common.notifications')"
              :dot="$store.getters['notifications/hasUnread']"
              :active="$route.name === 'app.notifications'"
              @click="notificationModal = true"
            />
          </NotificationsModal>

          <!-- Settings -->
          <HeaderMenuItem
            icon="mdi-cog"
            :to="{ name: 'app.settings' }"
            :active="$route.name === 'app.settings'"
          />
        </template>

        <HeaderAvatar />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ChatModal from "@/components/app/chat/ChatModal.vue";
import HeaderGlobalSearch from "./parts/HeaderGlobalSearch.vue";
import HeaderMenuItem from "./parts/HeaderMenuItem.vue";
import HeaderAvatar from "./parts/HeaderAvatar.vue";
import NotificationsModal from "@/components/app/notifications/NotificationsModal.vue";

export default {
  components: {
    ChatModal,
    HeaderGlobalSearch,
    HeaderMenuItem,
    HeaderAvatar,
    NotificationsModal,
  },

  data: () => ({
    chatModal: false,
    notificationModal: false,
  }),

  methods: {
    onLogoClick() {
      if (this.$route.name === "app") {
        this.$eventBus.$emit("feed:refresh");
        window.scroll({ top: 0, behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.col {
  max-height: 100%;
}
</style>
