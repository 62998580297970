<template>
  <v-menu v-model="menu" offset-y left content-class="mt-1">
    <template v-slot:activator="{ on }">
      <div
        class="d-flex cursor-pointer align-center"
        :class="{ 'is-small': $xs }"
        v-on="on"
      >
        <v-avatar :size="40" class="ml-2">
          <v-img
            :src="$utils.userImage($user, '40x40')"
            width="40"
            height="40"
            alt="User avatar"
          />
        </v-avatar>

        <div v-if="!$xs" class="ml-3">
          {{ $user.username }}

          <v-icon size="18" color="grey">mdi-chevron-down</v-icon>
        </div>
      </div>
    </template>

    <HeaderMenuList />
  </v-menu>
</template>

<script>
import HeaderMenuList from "./HeaderMenuList.vue";

export default {
  components: { HeaderMenuList },

  data: () => ({
    menu: false,
  }),
};
</script>

<style></style>
