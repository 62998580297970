<template>
  <v-app class="app-layout" :class="{ xs: $xs }" v-if="$user">
    <v-app-bar
      v-if="!$route.query.embed"
      app
      flat
      height="70"
      max-height="70"
      color="#070D17"
      style="z-index: 10"
    >
      <AppHeader />
    </v-app-bar>

    <v-main>
      <slot>
        <v-container fluid class="pt-3 pb-0 h-100 d-flex" style="gap: 20px">
          <div v-if="mdAndUp" class="payfoot-ads">
            <img src="https://placehold.jp/120x600.png" />
          </div>

          <router-view :key="$route.path" class="flex-grow-1" />

          <div v-if="mdAndUp" class="payfoot-ads">
            <img src="https://placehold.jp/120x600.png" />
          </div>
        </v-container>
      </slot>
    </v-main>

    <v-footer color="transparent">
      <AppFooter />
    </v-footer>

    <NewsletterModal />
    <PromiseDialogsWrapper />
  </v-app>
</template>

<script>
import AppHeader from "./AppHeader.vue";
import AppFooter from "./AppFooter.vue";
import NewsletterModal from "@/components/common/NewsletterModal.vue";
import { PromiseDialogsWrapper } from "vue-promise-dialogs";

export default {
  components: { AppHeader, AppFooter, NewsletterModal, PromiseDialogsWrapper },

  computed: {
    mdAndUp() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },

  created() {
    this.$store.dispatch("notifications/fetchUnread");
  },

  beforeDestroy() {
    this.$store.commit("notifications/CLEAR");
  },
};
</script>

<style lang="scss">
.app-layout {
  .container--fluid {
    max-width: 1785px;
  }

  .payfoot-ads {
    img {
      border-radius: $border-radius-root;
      position: sticky;
      top: 100px;
    }
  }
}
</style>
