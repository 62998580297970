<template>
  <v-menu v-model="menu" offset-y left :close-on-content-click="false">
    <!-- Activator -->
    <template v-slot:activator="{ on, attrs }">
      <slot v-bind="{ on, attrs }" />
    </template>

    <!-- Card -->
    <v-card
      width="400"
      class="chat-modal border rounded-lg overflow-hidden"
      :loading="$loading('chat/fetchConversations')"
    >
      <!-- Title -->
      <v-card-title>
        <h3>{{ $t("common.messages") }}</h3>
        <v-spacer />
        <router-link :to="{ name: 'app.chat' }" @click.native="menu = false">
          {{ $t("common.showAll") }}
        </router-link>
      </v-card-title>

      <!-- Main -->
      <v-card-text class="pa-0">
        <!-- Search -->
        <SearchInput
          v-model="$store.state.chat.search"
          @search="onSearch"
          class="rounded-lg px-4 mb-3"
        />

        <!-- Items -->
        <template v-for="(item, i) in conversations">
          <!-- Item -->
          <ChatListItem
            :key="item.id"
            :conversation="item"
            dense
            class="px-4 py-2"
            @click="onClick(item)"
          />

          <!-- Divider -->
          <div
            v-if="i < conversations.length - 1"
            :key="'divider-' + item.id"
            class="divider px-4"
          >
            <v-divider />
          </div>
        </template>

        <!-- Empty -->
        <v-alert v-if="isEmpty" type="info" text class="ma-4 mt-0">
          {{
            $t($store.state.chat.search ? "common.noDataFound" : "chat.noChats")
          }}
        </v-alert>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import ChatListItem from "@/components/app/chat/ChatListItem.vue";
import SearchInput from "@/components/common/SearchInput.vue";

export default {
  components: { ChatListItem, SearchInput },

  props: {
    value: Boolean,
  },

  computed: {
    menu: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },

    conversations() {
      return this.$store.getters["chat/conversations"].data.slice(0, 4);
    },

    isEmpty() {
      return (
        !this.$loading("chat/fetchConversations") &&
        this.conversations.length === 0
      );
    },
  },

  watch: {
    menu(val) {
      if (val && !this.$store.state.chat.loaded) {
        this.$store.dispatch("chat/fetchConversations");
      }
    },
  },

  methods: {
    onClick() {
      this.$router.push({ name: "app.chat" }).catch(() => {});
      this.menu = false;
    },

    onSearch() {
      this.$store.commit("chat/RESET_CONVERSATIONS");
      this.$store.dispatch("chat/fetchConversations");
    },
  },
};
</script>

<style lang="scss"></style>
