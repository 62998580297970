<template>
  <v-list color="ocean border overflow-hidden rounded-lg" width="auto">
    <!-- Profile -->
    <HeaderMenuItem
      icon="mdi-account"
      :text="$t('users.profile')"
      small
      :to="{ name: 'app.user', params: { id: $user.username } }"
    />

    <v-divider />

    <template v-if="$vuetify.breakpoint.smAndDown">
      <!-- Messages -->
      <HeaderMenuItem
        icon="mdi-chat-processing"
        :text="$t('common.messages')"
        :to="{ name: 'app.chat' }"
        :dot="$store.getters['chat/hasUnread']"
        small
      />

      <!-- Notifications -->
      <HeaderMenuItem
        icon="mdi-bell"
        :text="$t('common.notifications')"
        :to="{ name: 'app.notifications' }"
        :dot="$store.getters['notifications/hasUnread']"
        small
      />

      <!-- Settings -->
      <HeaderMenuItem
        icon="mdi-cog"
        :text="$t('common.settings')"
        :to="{ name: 'app.settings' }"
        small
      />

      <v-divider />
    </template>

    <!-- Logout -->
    <HeaderMenuItem
      icon="mdi-logout-variant"
      :text="$t('login.logout')"
      :loading="$loading('auth/logout')"
      small
      @click="$store.dispatch('auth/logout')"
    />
  </v-list>
</template>

<script>
import HeaderMenuItem from "./HeaderMenuItem.vue";

export default {
  components: { HeaderMenuItem },
};
</script>

<style lang="scss" scoped>
.v-list {
  padding: 0;
}

a {
  text-decoration: none;
}

.app-menu-item {
  &:hover {
    background-color: $ocean-light;
  }

  &:not(:hover) {
    background-color: inherit !important;
  }
}
</style>
