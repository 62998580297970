<template>
  <v-menu
    v-model="menu"
    offset-y
    left
    :close-on-content-click="false"
    style="z-index: 10"
  >
    <!-- Activator -->
    <template v-slot:activator="{ on, attrs }">
      <slot v-bind="{ on, attrs }" />
    </template>

    <!-- Card -->
    <v-card
      width="400"
      :loading="cardLoading"
      class="notifications-modal border rounded-lg overflow-hidden"
    >
      <!-- Header -->
      <div class="d-flex align-center pr-5">
        <!-- Title -->
        <v-card-title>
          <router-link
            class="notification__see-all_link"
            :to="{ name: 'app.notifications' }"
            @click.native="menu = false"
          >
            <h3>{{ $t("common.notifications") }}</h3>
          </router-link>
        </v-card-title>

        <v-spacer />

        <!-- Mark all as read button -->
        <a
          v-if="$store.getters['notifications/hasUnread']"
          href="#"
          class="text--secondary pt-1 text-decoration-none"
          @click.prevent="$store.dispatch('notifications/markAllAsRead')"
        >
          {{ $t("notifications.markAllAsRead") }}
        </a>
      </div>

      <!-- Main -->
      <v-card-text class="px-0 pt-0">
        <!-- Items -->
        <NotificationItem
          v-for="notification in unread.data.slice(
            0,
            $config.notifications.modal.perPage
          )"
          :key="notification.id"
          :notification="notification"
          small
          class="py-1 border-bottom"
          @close="menu = false"
        />

        <!-- Empty -->
        <v-alert v-if="isEmpty" type="info" text class="mx-4 mb-0">
          {{ $t("notifications.noUnread") }}
        </v-alert>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import NotificationItem from "./NotificationItem.vue";

export default {
  components: { NotificationItem },

  props: {
    value: Boolean,
  },

  computed: {
    menu: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },

    unread() {
      return this.$store.getters["notifications/unread"];
    },

    isEmpty() {
      return !this.$store.getters["notifications/hasUnread"];
    },

    cardLoading() {
      return (
        this.$loading("notifications/fetchUnread") ||
        this.$loading("notifications/markAllAsRead")
      );
    },
  },
};
</script>

<style lang="scss">
.v-card__title a {
  display: block;
  color: var(--v-primary-base);
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}

.notification-item:last-child {
  border-bottom: none;
}
</style>
