<template>
  <!-- Action buttons -->
  <div>
    <!-- Accept -->
    <v-btn
      color="primary"
      rounded
      small
      class="mr-2"
      :loading="$loading('groups/acceptInvite') === group.id"
      @click.stop="accept"
    >
      <v-icon left>mdi-check</v-icon>
      {{ $t("common.accept") }}
    </v-btn>

    <!-- Decline -->
    <v-btn
      color="secondary"
      rounded
      small
      class="ml-2"
      :loading="$loading('groups/declineInvite') === group.id"
      @click.stop="decline"
    >
      <v-icon left>mdi-cancel</v-icon>
      {{ $t("common.decline") }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    notification: Object,
    group: Object,
  },

  methods: {
    accept() {
      this.$store
        .dispatch("groups/acceptInvite", this.group.id)
        .then(() => {
          this.$store.commit("notifications/DELETE", this.notification);
          this.$emit("close");

          this.$router
            .push({
              name: "app.group",
              params: { id: this.group.id },
            })
            .catch(() => {});
        })
        .catch(() => {
          this.$toast.error("Error while accepting group.");
        });
    },

    decline() {
      this.$store
        .dispatch("groups/declineInvite", this.group.id)
        .then(() => {
          this.$store.commit("notifications/DELETE", this.notification);
        })
        .catch(() => {
          this.$toast.error("Error while declining group.");
        });
    },
  },
};
</script>

<style></style>
