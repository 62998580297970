<template>
  <v-dialog v-model="dialog" @click:outside="cancel">
    <v-img
      :src="require('@/assets/images/newsletter-bg.png')"
      max-width="1000"
      class="newsletter-modal d-flex mx-auto pa-5 rounded-lg"
      :class="{ xs: $xs }"
    >
      <div
        class="d-flex flex-column h-100 align-center justify-center text-center"
      >
        <h1>{{ $t("newsletter.title") }}</h1>

        <p class="my-13 font-weight-medium">
          {{ $t("newsletter.text") }}
        </p>

        <v-btn
          color="primary"
          :large="!$xs"
          :loading="loading"
          @click="subscribe"
        >
          {{ $t("newsletter.acceptButton") }}
        </v-btn>

        <button class="mt-2" @click="dialog = false">
          {{ $t("newsletter.declineButton") }}
        </button>
      </div>
    </v-img>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    loading: false,
  }),

  computed: {
    isSubscribed() {
      return !!this.$user.account_settings.subscribe_to_newsletter;
    },
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      if (this.isSubscribed) {
        return;
      }

      const lastSeen = localStorage.getItem("newsletter.last_seen");

      if (!lastSeen) {
        return this.open();
      }

      const diff = Math.abs(
        this.$utils.dayjs(lastSeen).diff(new Date(), "day")
      );

      if (diff < 10) {
        return;
      }

      this.open();
    },

    open() {
      this.dialog = true;
      localStorage.setItem("newsletter.last_seen", new Date());
    },

    subscribe() {
      this.loading = true;
      this.$store
        .dispatch("auth/updateAccountSettings", { subscribe_to_newsletter: 1 })
        .then(() => {
          this.$toast.success(this.$t("newsletter.thanks"), { duration: 5000 });
          this.dialog = false;
        })
        .catch(() => this.$toast.error("Error while subsribing to newsletter."))
        .finally(() => (this.loading = false));
    },

    cancel() {
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.newsletter-modal:not(.xs) {
  h1 {
    font-size: 40px;
  }

  p {
    font-size: 24px;
  }
}
</style>
