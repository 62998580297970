<template>
  <v-autocomplete
    ref="input"
    class="global-search icon-transform-none rounded"
    style="max-width: 250px"
    :search-input.sync="searchInput"
    :placeholder="$t('common.search')"
    :loading="loading"
    :items="results"
    :item-text="itemTextFunction"
    :item-value="(i) => i"
    attach
    append-icon="mdi-magnify"
    background-color="ocean"
    height="40"
    hide-no-data
    hide-details
    maxlength="20"
    no-filter
    rounded
    @keypress.enter="onEnter"
    @blur="onBlur"
    @input="onInput"
  >
    <!-- Item slot -->
    <template v-slot:item="{ item }">
      <!-- Show all -->
      <div
        v-if="item === 'full-search'"
        class="d-flex"
        @click.prevent="onEnter"
      >
        <v-list-item-icon class="mr-3 my-3">
          <v-icon v-text="'mdi-magnify'" />
        </v-list-item-icon>

        <v-list-item-content v-text="$t('common.searchMore')" />
      </div>

      <!-- Result item -->
      <template v-else>
        <!-- Avatar -->
        <v-list-item-avatar>
          <img :src="$utils.userImage(item)" alt="Avatar" />
        </v-list-item-avatar>

        <!-- Text -->
        <v-list-item-content>
          <!-- Title -->
          <v-list-item-title v-text="item.name || item.username" />
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import debounce from "@/utils/debounce";

export default {
  data: () => ({
    searchInput: "",
    results: [],
    loading: false,
  }),

  watch: {
    searchInput() {
      this.fetchData();
    },
  },

  methods: {
    fetchData: debounce(async function () {
      if (!this.searchInput || this.searchInput.length < 2) {
        this.results = [];
        return;
      }

      try {
        this.loading = true;

        const { data } = await this.$store.dispatch("search/global", {
          query: this.searchInput,
          short_response: true,
          per_page: 5,
        });

        this.results = [];

        if (data.users.length) {
          this.results.push(
            { header: this.$t("common.users") },
            { divider: true },
            ...data.users
          );
        }

        if (data.groups.length) {
          this.results.push(
            { header: this.$t("common.groups") },
            { divider: true },
            ...data.groups
          );
        }

        if (!this.results.length) {
          this.results.push({ header: this.$t("common.noResults") });
        }

        this.results.push({ divider: true }, "full-search");
      } catch (e) {
        this.$toast.error("Error while searching.");
      } finally {
        this.loading = false;
      }
    }, 500),

    itemTextFunction(item) {
      return item.username || item.name;
    },

    onInput(item) {
      if (!item) {
        return;
      }

      if (item === "full-search") {
        return this.onEnter();
      }

      this.$router
        .push({
          name: item.name ? "app.group" : "app.user",
          params: { id: item.username || item.id },
        })
        .then(() => {
          this.searchInput = "";
          this.results = [];
          this.$refs.input.blur();
        })
        .catch(() => {});
    },

    onBlur() {
      this.results = [];
    },

    onEnter() {
      this.$refs.input.blur();
      this.$refs.input.reset();
      this.$router
        .push({ name: "app.search", query: { q: this.searchInput } })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
.global-search {
  .v-menu__content {
    max-height: 500px !important;
    margin-top: 5px;
    overflow: hidden;

    .v-list {
      border-radius: 18px;
      overflow-y: auto;
      max-height: 500px;
      border: 1px solid $gray;
      background-color: $ocean !important;
      padding: 0 !important;

      .v-subheader {
        color: var(--v-primary-base);
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
</style>
