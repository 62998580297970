<template>
  <div
    class="app-menu-item text-center cursor-pointer pa-2"
    :class="{
      'd-inline-block rounded-lg mr-2': !small,
      'is-small d-flex align-center w-100': small,
    }"
    href="#"
    @click="onClick"
  >
    <div class="pos-rel" :class="{ 'd-flex align-center mr-3': small }">
      <v-icon
        v-if="!loading"
        :size="iconSize"
        :color="active ? 'primary' : 'white'"
      >
        {{ icon }}
      </v-icon>

      <spinner v-else :size="iconSize" />

      <v-badge
        v-if="dot"
        color="primary"
        dot
        class="pos-abs"
        style="top: 0; right: 0"
      />
    </div>

    <div v-if="small" class="font-weight-medium">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    text: String,
    loading: Boolean,
    small: Boolean,
    to: Object,
    dot: Boolean,
    active: Boolean,
  },

  computed: {
    iconSize() {
      return this.small ? 20 : 35;
    },
  },

  methods: {
    onClick() {
      if (this.to) {
        this.$router.push(this.to).catch(() => {});
      }

      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
:hover {
  transition: background-color 0.5s ease;
}
:not(.is-small):hover {
  background-color: $ocean;
}

.is-small:hover {
  background-color: $ocean;

  div,
  .v-icon {
    transition: background-color 0.5s ease;
    background-color: $ocean-light;
  }
}
</style>
