var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"notification-item cursor-pointer mx-0",class:{
    'is-read': _vm.notification.is_read,
    'is-unread': !_vm.notification.is_read,
    'is-small': _vm.small,
    'font-size-14': _vm.$vuetify.breakpoint.xsOnly,
  },on:{"click":_vm.onClick}},[_c('v-col',{staticClass:"pr-0 pr-sm-3",attrs:{"cols":"auto"}},[_c('v-avatar',{attrs:{"size":_vm.small || _vm.$vuetify.breakpoint.xsOnly ? 40 : 50}},[_c('v-img',{attrs:{"src":_vm.getImage(),"alt":"Image"},on:{"error":function($event){_vm.notification.sender.image.image_url =
            _vm.$utils.placeholderImage('50x50')}}})],1)],1),_c('v-col',{class:{ 'px-0': _vm.small }},[_c('div',{staticClass:"top d-flex align-end font-weight-medium mb-1"},[(!_vm.notification.is_read)?_c('div',{staticClass:"bullet mr-2 mr-sm-3",staticStyle:{"color":"var(--v-primary-base)"}},[_vm._v(" • ")]):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm.$t("notifications.titles." + _vm.notification.category))+" ")]),_c('div',{staticClass:"mx-2 mx-sm-3"}),(!_vm.small)?_c('Datetime',{staticClass:"text--secondary",staticStyle:{"font-size":"12px"},attrs:{"value":_vm.notification.created_at,"fromNow":true}}):_vm._e()],1),(_vm.small)?_c('Datetime',{staticClass:"text--secondary",staticStyle:{"font-size":"12px"},attrs:{"value":_vm.notification.created_at,"fromNow":true}}):_vm._e(),_c('div',{staticClass:"text text--secondary"},[_vm._v(" "+_vm._s(_vm.getNotificationText())+" ")]),_c('div',[(_vm.notification.category === 'UserFollowRequest')?_c('FollowRequestAction',{staticClass:"mt-2",attrs:{"notification":_vm.notification,"user":_vm.notification.notifable},on:{"close":function($event){return _vm.$emit('close')}}}):_vm._e(),(_vm.notification.category === 'GroupInvite')?_c('GroupInviteAction',{staticClass:"mt-2",attrs:{"notification":_vm.notification,"group":_vm.notification.notifable},on:{"close":function($event){return _vm.$emit('close')}}}):_vm._e()],1)],1),(!_vm.small)?_c('v-col',{attrs:{"cols":"auto","align-self":"center"}},[_c('NotificationMenu',{attrs:{"notification":_vm.notification}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }